import Page from '@app/components/layout/page/page';

import IngredientsTable from '@app/components/tables/ingredients-table/ingredients-table';

export default function IngredientsPage() {
  return (
    <Page name="Ingredients">
      <IngredientsTable />
    </Page>
  );
}
