import useAuth from '@app/auth/use-auth';
import PageError from '@app/components/layout/page/components/page-error';
import Page from '@app/components/layout/page/page';
import { useGetProduct } from '@shared/api';
import sentry from '@shared/services/sentry';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EditProductPage from './edit-product-page';
import ProductSummaryPage from './product-summary-page';

export default function EditProductRoute() {
  const { user } = useAuth();
  const { product_id: productUuid } = useParams();
  const {
    data: product,
    error: productError,
    status: getProductStatus,
  } = useGetProduct(productUuid!);

  const editWithDishFlow =
    user?.productCategory === 'dish' && !product?.hasCustomPpt;
  const pageName = editWithDishFlow ? 'Edit Product' : 'Product Summary';

  useEffect(() => {
    if (productError) sentry.log(productError);
  }, [productError]);

  const ErrorFallback = (
    <PageError
      errorTitle="Unable to load product"
      errorMessage="Please refresh the page or contact us if the error persists!"
    />
  );

  return (
    <Page
      name={pageName}
      status={getProductStatus}
      fallbackComponent={ErrorFallback}
    >
      {product && editWithDishFlow && <EditProductPage product={product} />}
      {product && !editWithDishFlow && <ProductSummaryPage product={product} />}
    </Page>
  );
}
