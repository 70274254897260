import { CompanyReport } from '@shared/api/types';
import Text from '@shared/components/content/text';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { ColumnDef } from '@tanstack/react-table';

export const reportTableColumns: ColumnDef<CompanyReport>[] = [
  {
    accessorKey: 'uuid',
    enableHiding: true,
  },
  {
    accessorKey: 'title',
    header: ({ column }) => <HeaderCell column={column} name="Title" />,
    cell: ({ getValue }) => {
      return <Text>{getValue<string>()}</Text>;
    },
  },
  {
    accessorKey: 'startDate',
    header: ({ column }) => <HeaderCell column={column} name="Start date" />,
    cell: ({ getValue }) => {
      return <Text>{getValue<string>()}</Text>;
    },
  },
  {
    accessorKey: 'endDate',
    header: ({ column }) => <HeaderCell column={column} name="End date" />,
    cell: ({ getValue }) => {
      return <Text>{getValue<string>()}</Text>;
    },
  },
];
