import useAuth from '@app/auth/use-auth';
import { ccfRoutes } from '@app/ccf/ccf-routes';
import { pcfRoutes } from '@app/pcf/pcf-routes';
import Loader from '@shared/components/loader';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { features } = user!;

  useEffect(() => {
    if (features.pcf) {
      navigate(pcfRoutes.PRODUCT_DASHBOARD);
    } else {
      navigate(ccfRoutes.COMPANY_REPORTS);
    }
  }, [features, navigate]);

  return <Loader />;
}
