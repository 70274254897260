import Page from '@app/components/layout/page/page';
import Text from '@shared/components/content/text';
import { Button, ButtonProps } from '@shared/components/ui/button';
import { useFoodImport } from '../scope3/food-import/food-import-layout';

interface CCFCategoryIntroPageProps {
  category: string;
  description: string;
  image: string;
  primaryActionProps?: ButtonProps;
  secondaryActionProps?: ButtonProps;
}

export default function CCFCategoryIntroPage({
  category,
  description,
  image,
  primaryActionProps,
  secondaryActionProps,
}: CCFCategoryIntroPageProps) {
  const { reportTitle } = useFoodImport();
  return (
    <Page name={`${category} | ${reportTitle}`}>
      <div className=" max-w-content-center space-y-4 ">
        <div className="h-48 w-full bg-primary-300"></div>
        <Text>{description}</Text>
        <div className="mx-auto flex w-full justify-between">
          <Button
            variant="secondary"
            className="border border-primary-700 text-primary-700"
            {...secondaryActionProps}
          >
            Learn more
          </Button>

          <Button className="bg-primary-700" {...primaryActionProps}>
            Get started
          </Button>
        </div>
      </div>
    </Page>
  );
}
