import Text from '@shared/components/content/text';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { NumberCell } from '@shared/components/data-table/cells/number-cell';
import { DataTable } from '@shared/components/data-table/data-table';
import { useDataTable } from '@shared/components/data-table/hooks/use-data-table';
import { ColumnDef } from '@tanstack/react-table';
import FoodImportTablePopover from './food-import-table-popover';

const data = [
  {
    remoteId: 'ID-101',
    supplier: 'Greenfield Supplies',
    name: 'Premium Basmati Rice',
    quantity: 1000,
    unit: 'kg',
  },
  {
    remoteId: 'ID-102',
    supplier: 'PureHarvest Organics',
    name: 'Organic Quinoa',
    quantity: 750,
    unit: 'kg',
  },
  {
    remoteId: 'ID-103',
    supplier: 'Alpine Dairy Co.',
    name: 'Whole Milk',
    quantity: 500,
    unit: 'L',
  },
];

const columns: ColumnDef<{
  remoteId: string;
  supplier: string;
  name: string;
  quantity: number;
  unit: string;
}>[] = [
  {
    accessorKey: 'remoteId',
    header: ({ column }) => (
      <HeaderCell
        column={column}
        name="ID (optional)"
        unit={<FoodImportTablePopover section="remoteId" />}
        unitWrap={false}
        className="text-nowrap"
      />
    ),
    cell: ({ getValue }) => {
      return <Text>{getValue<string>()}</Text>;
    },
    enableSorting: false,
  },
  {
    accessorKey: 'supplier',
    header: ({ column }) => (
      <HeaderCell
        column={column}
        name="Supplier (optional)"
        className="text-nowrap"
      />
    ),
    cell: ({ getValue }) => {
      return <Text>{getValue<string>()}</Text>;
    },
    enableSorting: false,
  },
  {
    accessorKey: 'name',
    header: ({ column }) => (
      <HeaderCell
        column={column}
        name="Name"
        unit={<FoodImportTablePopover section="name" />}
        unitWrap={false}
      />
    ),
    cell: ({ getValue }) => {
      return <Text>{getValue<string>()}</Text>;
    },
    enableSorting: false,
  },
  {
    accessorKey: 'quantity',
    header: ({ column }) => (
      <HeaderCell column={column} name="Quantity" numberColumn />
    ),
    cell: ({ getValue }) => {
      return <NumberCell number={getValue<number>()} />;
    },
    enableSorting: false,
  },
  {
    accessorKey: 'unit',
    header: ({ column }) => (
      <HeaderCell
        column={column}
        name="Unit"
        unit={<FoodImportTablePopover section="unit" />}
        unitWrap={false}
      />
    ),
    cell: ({ getValue }) => {
      return <Text>{getValue<string>()}</Text>;
    },
    enableSorting: false,
  },
];

export default function FoodImportTable() {
  const { table } = useDataTable({
    data,
    columns,
  });

  return <DataTable table={table} className="bg-gray-00 text-left shadow-lg" />;
}
