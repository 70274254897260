import Page from '@app/components/layout/page/page';
import GuideTemplate from '@shared/components/guide-template';
import { useNavigate } from 'react-router-dom';
import OnboardingGuideCards from './components/onboarding-guide-card';

import AvgRatingScreenshot from './assets/avg-rating-screenshot.png';
import ReportScreenshot from './assets/report-screenshot.png';

export default function ReportsGuide() {
  const navigate = useNavigate();

  return (
    <Page name="Reports guide">
      <GuideTemplate
        preTitle="Getting started"
        title="See the full picture"
        description="Get a high-level overview of your data, understand which products or collections have the most impact, build reports to understand and analyse trends over time. "
        primaryActionText="Next"
        primaryActionProps={{
          onClick: () => navigate('/guide/get-in-touch'),
        }}
        secondaryActionText="Back"
        secondaryActionProps={{
          onClick: () => navigate('/guide/assessments'),
        }}
      >
        <div className="grid grid-cols-1 gap-8 text-center lg:grid-cols-3 lg:gap-4">
          <OnboardingGuideCards
            step="Dashboard"
            image={AvgRatingScreenshot}
            description="Build a custom dashboard tailored to specific menus or product ranges."
          />
          <OnboardingGuideCards
            step="Reports"
            image={ReportScreenshot}
            description="Track your total impact by measuring emissions over a selected timeframe across sales, purchases, or production data."
            addOn={true}
          />

          <OnboardingGuideCards
            step="Corporate Carbon Footprint"
            image=""
            comingSoon={true}
            description="Full Scope 1-3 Company Carbon Footprint to meet requirements, regulations and help you navigate net zero."
            addOn={true}
          />
        </div>
      </GuideTemplate>
    </Page>
  );
}
