import useAuth from '@app/auth/use-auth';
import { PageHeader } from '@app/components/layout/page/components/page-header';
import Page from '@app/components/layout/page/page';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ccfRoutes } from './ccf-routes';

export default function CcfAccessPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { features } = user!;

  useEffect(() => {
    if (features.ccf) {
      navigate(ccfRoutes.COMPANY_REPORTS);
    }
  }, [features, navigate]);

  return (
    <Page name="Access company reports">
      <PageHeader name="Company reports" includeBreadcrumb={false} />
    </Page>
  );
}
