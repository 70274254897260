import Page from '@app/components/layout/page/page';
import GuideTemplate from '@shared/components/guide-template';
import { useNavigate } from 'react-router-dom';

import HomeScreenshot from './assets/home-screenshot.png';

export default function WelcomePage() {
  const navigate = useNavigate();
  return (
    <Page name="Welcome page">
      <GuideTemplate
        preTitle="Getting started"
        title="Welcome to My Emissions"
        description="We’ve put together a short guide to get you started and help you make the most of the My Emissions platform."
        primaryActionProps={{
          onClick: () => navigate('/guide/products'),
        }}
        primaryActionText="Let's go!"
        secondaryActionProps={{
          onClick: () => navigate('/'),
        }}
        secondaryActionText="Skip for now"
      >
        <div className="flex justify-center">
          <img
            src={HomeScreenshot}
            alt="Screenshot of My Emissions dashboard"
            className="max-h-[365px] w-full max-w-lg rounded-lg border border-teal-300 shadow-xl md:max-w-2xl"
          />
        </div>
      </GuideTemplate>
    </Page>
  );
}
