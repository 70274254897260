import ApiValidationError from '@app/api/errors';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateProduct } from '@shared/api';
import {
  PatchedProductWithRelations,
  ProductIngredientEntityTypeEnum,
} from '@shared/api/types';
import { useSetFormFields } from '@shared/components/product-form/hooks/use-set-form-fields';
import ProductForm from '@shared/components/product-form/product-form';
import {
  ingredientsSchema,
  netWeightSchema,
  recipeNameSchema,
  servingsSchema,
} from '@shared/components/product-form/schema';
import { useToast } from '@shared/components/ui/use-toast';

import { cn } from '@shared/lib/utils';
import sentry from '@shared/services/sentry';
import { ClassValue } from 'clsx';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

const createProductFormSchema = z.object({
  recipeName: recipeNameSchema,
  servings: servingsSchema,
  netWeight: netWeightSchema,
  ingredients: ingredientsSchema,
});

interface CreateProductPageProps {
  className?: ClassValue;
}

export default function CreateProductPage({
  className,
}: CreateProductPageProps) {
  const { mutateAsync: createProduct } = useCreateProduct();

  const { toast } = useToast();
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof createProductFormSchema>>({
    resolver: zodResolver(createProductFormSchema),
    defaultValues: {
      servings: 1,
      ingredients: [
        {
          entityUuid: '',
          quantity: 1,
          unitUuid: '',
          entityType: ProductIngredientEntityTypeEnum['base_food'],
        },
      ],
    },
  });

  const handleCreate = async (
    data: z.infer<typeof createProductFormSchema>
  ) => {
    try {
      const newProduct = await createProduct({
        data: {
          name: data.recipeName,
          servings: data.servings,
          netWeight: data.netWeight || null,
          ingredients:
            data.ingredients as PatchedProductWithRelations['ingredients'],
        },
      });

      navigate(`/products/${newProduct.uuid}/edit`);
    } catch (error) {
      const description =
        error instanceof ApiValidationError
          ? error.message
          : 'Please try again or contact us if the error persists';
      toast({
        title: 'An error occurred while creating your product',
        description,
        variant: 'destructive',
      });
      sentry.log(error);
    }
  };

  return (
    <div className={cn('', className)}>
      <ProductForm
        form={form}
        handleSubmit={handleCreate}
        fields={useSetFormFields(createProductFormSchema)}
        includeReggie
      />
    </div>
  );
}
