import Page from '@app/components/layout/page/page';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateCompanyReport } from '@shared/api';
import DatePickerFormField from '@shared/components/form/date-picker-form-field';
import GuideTemplate from '@shared/components/guide-template';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import {
  addWeeks,
  addYears,
  formatISO,
  isAfter,
  isBefore,
  isFuture,
  set,
  subDays,
  subWeeks,
  subYears,
} from 'date-fns';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';
import { date, object, z } from 'zod';
import { ccfRoutes } from '../ccf-routes';

export const reportSetupSchema = object({
  startDate: date({
    required_error: 'Start date is required',
  }),
  endDate: date({
    required_error: 'End date is required',
  }),
});

const getEndDateFromStartDate = (startDate: Date) => {
  return subDays(addYears(startDate, 1), 1);
};

export default function CCFSetup() {
  const [isStartDateCalenderOpen, setIsStartDateCalenderOpen] = useState(false);
  const [isEndDateCalenderOpen, setIsEndDateCalenderOpen] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();
  const { mutateAsync: createCompanyReport, isLoading: creatingReport } =
    useCreateCompanyReport();

  const form = useForm<z.infer<typeof reportSetupSchema>>({
    resolver: zodResolver(reportSetupSchema),
  });

  const { getValues } = form;

  const handleStartDateChange = () => {
    const startDate = getValues('startDate');
    const endDate = getEndDateFromStartDate(startDate);
    setIsStartDateCalenderOpen(false);
    form.setValue('endDate', endDate);
  };

  const handleEndDateChange = () => {
    form.setValue('endDate', getValues('endDate'));
    setIsEndDateCalenderOpen(false);
  };

  const onSubmit = async (data: z.infer<typeof reportSetupSchema>) => {
    try {
      const { uuid } = await createCompanyReport({
        data: {
          startDate: formatISO(data.startDate, { representation: 'date' }),
          endDate: formatISO(data.endDate, { representation: 'date' }),
        },
      });
      navigate(generatePath(ccfRoutes.COMPANY_REPORT, { report_uuid: uuid }));
    } catch (error) {
      sentry.log(error);
      toast({
        title: 'An error has occurred trying to create the report',
        variant: 'destructive',
      });
    }
  };

  return (
    <Page name="Report setup">
      <GuideTemplate
        title="Your reporting period"
        description=" We recommend aligning your carbon reporting with your financial year as some regulations, such as CSRD, tie carbon reports to your financial reports"
        primaryActionProps={{
          disabled: creatingReport,
          loading: creatingReport,
          onClick: () => form.handleSubmit(onSubmit)(),
        }}
        secondaryActionProps={{
          disabled: creatingReport,
          onClick: () => navigate(generatePath('/company-reports/welcome')),
        }}
        primaryActionText="Set period"
        secondaryActionText="Go back"
      >
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DatePickerFormField
              name="startDate"
              label="Start date"
              includeErrorMessage
              className="mb-6"
              handleSelect={handleStartDateChange}
              defaultMonth={
                form.getValues('startDate') ||
                set(subYears(new Date(), 1), {
                  date: 1,
                  month: 0,
                })
              }
              disabledDates={isFuture}
              isCalenderOpen={isStartDateCalenderOpen}
              setIsCalenderOpen={setIsStartDateCalenderOpen}
            />
            <DatePickerFormField
              name="endDate"
              label="End date"
              placeholder="Select a start date"
              handleSelect={handleEndDateChange}
              defaultMonth={getValues('endDate')}
              disabledDates={(date) => {
                const endDate = getEndDateFromStartDate(getValues('startDate'));
                return (
                  isBefore(date, subWeeks(endDate, 2)) ||
                  isAfter(date, addWeeks(endDate, 2))
                );
              }}
              disabled={
                !getValues('startDate') || getValues('startDate') === null
              }
              isCalenderOpen={isEndDateCalenderOpen}
              setIsCalenderOpen={setIsEndDateCalenderOpen}
              required
            />
          </form>
        </Form>
      </GuideTemplate>
    </Page>
  );
}
