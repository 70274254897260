import Page from '@app/components/layout/page/page';
import GuideTemplate from '@shared/components/guide-template';
import { useNavigate } from 'react-router-dom';
import OnboardingGuideCards from './components/onboarding-guide-card';

import AELabels from './assets/a-labels.png';
import ProductDetailScreenshot from './assets/detailed-breakdown-screenshot.png';
import InsightsScreenshot from './assets/insights-screenshot.png';

export default function AssessmentsGuide() {
  const navigate = useNavigate();

  return (
    <Page name="Assessments guide">
      <GuideTemplate
        preTitle="Getting started"
        title="Understand your emissions"
        description="Gain insights into your product's carbon footprint with a detailed breakdown of emissions by lifecycle stage, carbon rating, and ingredient analysis."
        primaryActionText="Next"
        primaryActionProps={{
          onClick: () => navigate('/guide/reports'),
        }}
        secondaryActionText="Back"
        secondaryActionProps={{
          onClick: () => navigate('/guide/products'),
        }}
      >
        <div className="grid grid-cols-1 gap-8 text-center lg:grid-cols-3 lg:gap-4">
          <OnboardingGuideCards
            step="Our A-E carbon labels"
            image={AELabels}
            description="Clearly communicate your impact through simple and easy to understand carbon labels"
          />
          <OnboardingGuideCards
            step="Detailed breakdown"
            image={ProductDetailScreenshot}
            description="Identify emissions hotspots within your products key lifecycle stages"
          />
          <OnboardingGuideCards
            step="Insights"
            image={InsightsScreenshot}
            description="Enhance your carbon literacy and gain a deeper understanding of your highest-emission ingredients"
          />
        </div>
      </GuideTemplate>
    </Page>
  );
}
