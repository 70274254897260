import Page from '@app/components/layout/page/page';

export default function OtherEnergyEmissionsPage() {
  return (
    <Page
      title="Scope 1 - Other energy emissions"
      name="Scope 1 - Other energy emissions"
    >
      Other Energy emissions
    </Page>
  );
}
