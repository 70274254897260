import { array, object, string, z } from 'zod';

export const editCCFFoodFormSchema = object({
  name: string().min(1),
  remoteId: string().optional(),
  matchedFoodUuid: string().min(1, {
    message: 'Please select a food',
  }),
  records: array(
    object({
      uuid: string().min(1),
      quantity: z.coerce.number().min(0.001),
      unit: string().min(1),
      timePeriod: string().min(1),
    })
  ),
});
