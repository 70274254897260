import { Icons } from '@shared/components/content/icons';
import ProgressBarWithDuration from '@shared/components/progress-bar-with-duration';

interface FoodImportLoaderProps {
  forceComplete?: boolean;
  duration?: number;
}

export default function FoodImportLoader({
  forceComplete,
  duration = 4000,
}: FoodImportLoaderProps) {
  return (
    <div>
      <div className="flex w-[320px] items-center justify-center">
        <ProgressBarWithDuration
          duration={duration}
          statusMessages={[
            {
              message: 'Uploading import file...',
              icon: <Icons.scanSearch className="size-5" />,
            },
            {
              message: 'Matching foods...',
              icon: <Icons.carrot className="size-5" />,
            },
            {
              message: 'Calculating emissions...',
              icon: <Icons.scale className="size-5" />,
            },
            {
              message: 'Finalising import...',
              icon: <Icons.loader2 className="size-5 animate-spin" />,
            },
          ]}
          forceComplete={forceComplete}
        />
      </div>
    </div>
  );
}
