import InfoPopOver from '@app/components/InfoPopOver';
import { PageHeader } from '@app/components/layout/page/components/page-header';
import { Box, Grid, Typography } from '@mui/material';
import { useGetDashboard } from '@shared/api';
import Text from '@shared/components/content/text';
import useAuth from '../../auth/use-auth';
import Page from '../../components/layout/page/page';
import DashboardEmptyPage from './dashboard-empty-page';
import AverageLifeCycleEmissions from './sections/AverageLifeCycleEmissions';
import ProductRatings from './sections/ProductRatings';
import ProductsBox from './sections/Products';
import TopStats from './sections/TopStats';

const DashboardPage: React.FC = () => {
  const { user } = useAuth();

  const organizationName = user.organizationName;

  const { data: dashboardData, status } = useGetDashboard();

  return (
    <Page name="Dashboard" status={status}>
      {!!dashboardData && (
        <div className="flex h-full flex-col">
          <PageHeader name="Product dashboard" includeBreadcrumb={false} />
          {dashboardData.totalNumberOfProducts > 0 ? (
            <Grid container rowSpacing={3} columnSpacing={2}>
              <TopStats
                averageProductEmissionsPerKg={
                  dashboardData.averageProductEmissionsPerKg
                }
                totalNumberOfProducts={dashboardData!.totalNumberOfProducts}
                numberOfIngredients={dashboardData!.numberIngredients}
                numberProductsDraft={dashboardData.numberProductsDraft}
                numberProductsEditing={dashboardData.numberProductsEditing}
                numberProductsInProgress={
                  dashboardData.numberProductsInProgress
                }
                numberProductsCompleted={dashboardData.numberProductsComplete}
              />
              {dashboardData.numberProductsComplete > 0 ||
              dashboardData.numberProductsProvisional > 0 ? (
                <>
                  <Grid
                    item
                    container
                    rowSpacing={3}
                    columnSpacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ProductRatings
                      productEmissionsCategoryBreakdown={
                        dashboardData.productEmissionsCategoryBreakdown
                      }
                      averageEmissionsCategory={
                        dashboardData!.averageEmissionsCategory
                      }
                    />
                    <AverageLifeCycleEmissions
                      averageLifeCycleStageEmissionsPerKg={
                        dashboardData!.averageLifeCycleStageEmissionsPerKg
                      }
                    />
                  </Grid>
                  <Grid item container rowSpacing={3} columnSpacing={2}>
                    {dashboardData.products !== null ? (
                      <Grid item xs={12} sm={12} md={6} mt={2}>
                        <Box style={{ marginBottom: '20px' }}>
                          <Box display="flex" alignItems="center" mb="1.3rem">
                            <Typography
                              variant="h6"
                              sx={{
                                color: 'text.primary',
                                marginRight: '0.5rem',
                              }}
                            >
                              Your Products
                            </Typography>
                          </Box>
                          <ProductsBox
                            items={dashboardData!.products}
                            numShown={5}
                            itemsType="Products"
                          />
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={12} md={6} mt={2}>
                        <Box style={{ marginBottom: '20px' }}>
                          <Box display="flex" alignItems="center" mb="1.3rem">
                            <Typography
                              variant="h6"
                              sx={{
                                color: 'text.primary',
                                marginRight: '0.5rem',
                              }}
                            >
                              Lowest Emissions Products
                            </Typography>
                            <InfoPopOver subjectMatter="Lowest Emissions Product" />
                          </Box>
                          <ProductsBox
                            items={dashboardData!.lowestEmissionsProducts}
                            numShown={3}
                            itemsType="Products"
                          />
                        </Box>
                        <Box>
                          <Box display="flex" alignItems="center" mb="1.3rem">
                            <Typography
                              variant="h6"
                              sx={{
                                color: 'text.primary',
                                marginRight: '0.5rem',
                              }}
                            >
                              Highest Emissions Products
                            </Typography>
                            <InfoPopOver subjectMatter="Highest Emissions Product" />
                          </Box>
                          <ProductsBox
                            items={dashboardData!.highestEmissionsProducts}
                            numShown={3}
                            itemsType="Products"
                          />
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={6} mt={2}>
                      <Box style={{ marginBottom: '20px' }}>
                        <Box display="flex" alignItems="center" mb="1.3rem">
                          <Typography
                            variant="h6"
                            sx={{
                              color: 'text.primary',
                              marginRight: '0.5rem',
                            }}
                          >
                            Most Used Ingredients
                          </Typography>
                          <InfoPopOver subjectMatter="Most Used Ingredients" />
                        </Box>
                        <ProductsBox
                          items={dashboardData!.mostUsedFoods}
                          numShown={7}
                          itemsType="Foods"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <div className="flex size-full items-center justify-center">
                  <Text variant="section">
                    Data about your products will appear here once a product has
                    been assessed.
                  </Text>
                </div>
              )}
            </Grid>
          ) : (
            <DashboardEmptyPage />
          )}
        </div>
      )}
    </Page>
  );
};

export default DashboardPage;
