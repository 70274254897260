import { PageHeader } from '@app/components/layout/page/components/page-header';
import Page from '@app/components/layout/page/page';
import { Button } from '@shared/components/ui/button';
import { generatePath, useNavigate } from 'react-router-dom';
import { ccfRoutes } from '../ccf-routes';
import CCFReportTable from './components/ccf-report-table';

export default function CCFListPage() {
  const navigate = useNavigate();

  return (
    <Page name="Company reports">
      <PageHeader
        name="Company reports"
        toolbar={
          <Button
            type="button"
            onClick={() =>
              navigate(generatePath(ccfRoutes.REPORT_SETUP_WELCOME))
            }
          >
            New report
          </Button>
        }
      />
      <CCFReportTable type="card" />
    </Page>
  );
}
