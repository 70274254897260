/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `month` - Month
* `quarter` - Quarter
* `year` - Year
 */
export type TimePeriodTypeEnum = typeof TimePeriodTypeEnum[keyof typeof TimePeriodTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimePeriodTypeEnum = {
  month: 'month',
  quarter: 'quarter',
  year: 'year',
} as const;
