import { CompanyReport } from '@shared/api/types';
import Text from '@shared/components/content/text';
import { Card, CardContent } from '@shared/components/ui/card';
import { cn } from '@shared/lib/utils';
import { format } from 'date-fns';
import React from 'react';

interface CCFReportCardProps extends React.HTMLAttributes<HTMLDivElement> {
  report: CompanyReport;
}

export default function CCFReportCard({
  report,
  className,
  onClick,
}: CCFReportCardProps) {
  return (
    <div className={cn('p-0.5', className)} onClick={onClick}>
      <Card className="min-w-96 border-primary-50 transition-all hover:border-primary-200 hover:shadow-sm">
        <CardContent className="flex flex-row items-center pb-2 pt-3">
          <div>
            <Text variant="cardTitle" className="ml-2">
              {report.title}
            </Text>
            <Text variant="subtle" className="ml-2 mt-1">
              {format(report.startDate, 'do MMMM yyyy')} -{' '}
              {format(report.endDate, 'do MMMM yyyy')}
            </Text>
          </div>
        </CardContent>
        <CardContent className="flex justify-between"></CardContent>
      </Card>
    </div>
  );
}
