import {
  useGetActivityTypeUnits,
  useGetCompanyReportPeriods,
} from '@shared/api';
import { Icons } from '@shared/components/content/icons';
import InputFormField from '@shared/components/form/input-form-field';
import SelectFormField, {
  SelectOption,
} from '@shared/components/form/select-form-field';
import { RepeatableRowComponentProps } from '@shared/components/form/types';
import { Button } from '@shared/components/ui/button';
import { FormLabel } from '@shared/components/ui/form';
import { Skeleton } from '@shared/components/ui/skeleton';
import { cn } from '@shared/lib/utils';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useCCF } from '../ccf-layout';

interface CCFFoodActivityRowProps extends RepeatableRowComponentProps {}

export default function CCFFoodActivityRow({
  index,
  remove,
  setDisableAdd,
}: CCFFoodActivityRowProps) {
  const { reportUuid } = useCCF();
  const {
    data: units,
    isLoading: unitsIsLoading,
    isError: unitsError,
  } = useGetActivityTypeUnits('food');
  const { data: periods, isLoading: isPeriodsLoading } =
    useGetCompanyReportPeriods(reportUuid);

  const unitOptions = useMemo(
    () =>
      units?.map((unit) => ({
        value: unit.uuid,
        label: unit.name,
      })) || [],
    [units]
  ) as SelectOption[];

  const getPeriodOptions = useMemo(() => {
    return periods?.map(({ uuid, name }) => ({
      value: uuid,
      label: name,
    })) as SelectOption[] | [];
  }, [periods]);

  const { getValues } = useFormContext();

  useEffect(() => {
    setDisableAdd(true);
  });

  if (unitsIsLoading || isPeriodsLoading) {
    return (
      <div className="grid grid-cols-12 items-end gap-2">
        <Skeleton className="col-span-7 h-10 rounded-lg" />
        <Skeleton className="col-span-2 h-10 rounded-lg" />
        <Skeleton className="col-span-2 h-10 rounded-lg" />
      </div>
    );
  }

  if (!units) {
    throw new Error(
      `Failed to load units for ccf food activity rows ${unitsError || 'No data'}`
    );
  }

  if (!periods) {
    throw new Error(
      `Failed to load periods for food activity rows ${isPeriodsLoading || 'No data'}`
    );
  }

  return (
    <div className="grid grid-cols-12 items-start gap-2">
      <SelectFormField
        name={`records.${index}.timePeriod`}
        options={getPeriodOptions}
        disabled
        label={index === 0 ? 'Period' : ''}
        className="col-span-5"
      />
      <InputFormField
        name={`records.${index}.quantity`}
        label={index === 0 ? 'Quantity' : ''}
        className="col-span-4"
        type="number"
      />
      <SelectFormField
        name={`records.${index}.unit`}
        options={unitOptions}
        label={index === 0 ? 'Unit' : ''}
        className="col-span-2"
      />
      <div className="col-span-1 grid items-end">
        {index === 0 && (
          <FormLabel className="mb-0 opacity-0">Remove</FormLabel>
        )}
        <Button
          type="button"
          variant="outline"
          size="sm"
          className={cn('size-10 disabled:opacity-30', {
            'mt-2': index === 0,
          })}
          onClick={() => remove(index)}
          disabled={getValues('records').length === 1}
        >
          <Icons.x className="size-4" />
        </Button>
      </div>
    </div>
  );
}
