import { Fields, SelectOption } from 'react-spreadsheet-import/types/types';

const maxLengthValidation = (length: number) => ({
  rule: 'regex',
  value: `^.{0,${length}}$`,
  errorMessage: `Must not be longer than ${length} characters`,
  level: 'error',
});

const requiredValidation = (name: string) => ({
  rule: 'required',
  errorMessage: `${name} is required`,
  level: 'error',
});

export const getFoodFields: (units: SelectOption[]) => Fields<string> = (
  units
) => [
  {
    label: 'ID',
    key: 'remoteId',
    alternateMatches: ['product id', 'sku', 'id'],
    fieldType: {
      type: 'input',
    },
    example: '#HK-TOM-1',
    validations: [maxLengthValidation(240)],
  },
  {
    label: 'Supplier',
    key: 'supplier',
    alternateMatches: ['supplier', 'vendor', 'manufacturer'],
    fieldType: {
      type: 'input',
    },
    example: 'HK Foods',
    validations: [maxLengthValidation(240)],
  },
  {
    label: 'Name',
    key: 'name',
    alternateMatches: ['title', 'description'],
    fieldType: {
      type: 'input',
    },
    example: 'Cherry tomatoes',
    validations: [requiredValidation('Name'), maxLengthValidation(240)],
  },
  {
    label: 'Quantity',
    key: 'quantity',
    alternateMatches: ['qty', 'amount'],
    fieldType: {
      type: 'input',
    },
    example: '439',
    validations: [maxLengthValidation(30)],
  },
  {
    label: 'Unit',
    key: 'unit',
    alternateMatches: ['uom', 'units'],
    fieldType: {
      type: 'select',
      options: units,
    },
    example: 'kg',
    validations: [requiredValidation('Unit'), maxLengthValidation(36)],
  },
];
