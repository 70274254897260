import { Route, Routes as RoutesContainer } from 'react-router-dom';
import AuthLayout from './auth/auth-layout';
import ForgotPasswordPage from './auth/pages/forgot-password-page';
import LoginPage from './auth/pages/login-page';
import PasswordResetPage from './auth/pages/password-reset-page';
import useAuth from './auth/use-auth';
import CcfAccessPage from './ccf/ccf-access-page';
import { ccfRoutes } from './ccf/ccf-routes';
import ReportHubPage from './ccf/reports/ccf-hub-page';
import CCFLayout from './ccf/reports/ccf-layout';
import CCFListPage from './ccf/reports/ccf-list-page';
import CCFSetup from './ccf/reports/ccf-setup';
import ReportSetupWelcome from './ccf/reports/ccf-setup-welcome';
import MobileCombustionPage from './ccf/reports/scope1/mobile-combustion-page';
import OtherGasEmissionsPage from './ccf/reports/scope1/other-gas-emissions-page';
import StationaryCombustionPage from './ccf/reports/scope1/stationary-combustion-page';
import ElectricityPage from './ccf/reports/scope2/electricity-page';
import OtherEnergyEmissionsPage from './ccf/reports/scope2/other-energy-emissions-page';
import CCFFoodAndDrinkPage from './ccf/reports/scope3/ccf-food-page';
import FoodDownloadTemplatePage from './ccf/reports/scope3/food-import/food-download-template-page';
import FoodImportLayout from './ccf/reports/scope3/food-import/food-import-layout';
import FoodImportLoadingPage from './ccf/reports/scope3/food-import/food-import-loading-page';
import FoodImportPage from './ccf/reports/scope3/food-import/food-import-page';
import ComparisonPage from './components/comparisons/comparison-page';
import AuthenticatedRoutes from './components/layout/authenticated-routes';
import FeatureRequired from './components/layout/feature-required';
import Home from './components/layout/home';
import Layout from './components/layout/Layout';
import PageErrorForbidden from './components/layout/page/components/page-error-forbidden';
import PageNotFound from './components/layout/PageNotFound';
import ProductResult from './components/product-form/product-result';
import CheckEmailPage from './onboarding/email-verification/check-email-page';
import VerifyEmailPage from './onboarding/email-verification/verify-email-page';
import AssessmentsGuide from './onboarding/guide/assessments-guide';
import GetInTouch from './onboarding/guide/get-in-touch';
import OnboardingGuideLayout from './onboarding/guide/onboarding-guide-layout';
import ProductsGuide from './onboarding/guide/products-guide';
import ReportsGuide from './onboarding/guide/reports-guide';
import WelcomePage from './onboarding/guide/welcome-page';
import AboutCompanyPage from './onboarding/signup/additional-signup-steps/about-company-page';
import CompanyAimsPage from './onboarding/signup/additional-signup-steps/company-aims-page';
import SignupFlowLayout from './onboarding/signup/signup-flow-layout';
import SignupPage from './onboarding/signup/signup-page';
import CollectionDetailIngredients from './pages/collections/collection-detail/collection-detail-ingredients';
import CollectionDetailPage from './pages/collections/collection-detail/collection-detail-page';
import CollectionDetailProducts from './pages/collections/collection-detail/collection-detail-products';
import CollectionDetailSummary from './pages/collections/collection-detail/collection-detail-summary';
import CollectionListPage from './pages/collections/collection-list-page';
import CreateCollectionPage from './pages/collections/create-collection-page';
import EditCollectionPage from './pages/collections/edit-collection-page';
import DashboardPage from './pages/dashboard/dashboard-page';
import CreateIngredientPage from './pages/ingredients/CreateIngredientPage';
import IngredientInsightsPage from './pages/ingredients/ingredient-insights-page';
import IngredientsLayout from './pages/ingredients/ingredients-layout';
import IngredientsPage from './pages/ingredients/ingredients-page';
import PackagingCreatePage from './pages/packaging/CreatePackagingPage';
import PackagingDetailPage from './pages/packaging/packaging-detail-page';
import PackagingListPage from './pages/packaging/packaging-list-page';
import CreateProcessingPage from './pages/processing/CreateProcessingPage';
import ProcessingPage from './pages/processing/processing-list-page';
import CreateProductPage from './pages/product/create-product-page';
import EditProductRoute from './pages/product/edit-product-route';
import ProductDetailPage from './pages/product/product-detail/product-detail-page';
import ProductDetailIngredients from './pages/product/product-detail/tabs/product-detail-ingredients';
import ProductDetailPackaging from './pages/product/product-detail/tabs/product-detail-packaging';
import ProductDetailProcessing from './pages/product/product-detail/tabs/product-detail-processing';
import ProductDetailSummary from './pages/product/product-detail/tabs/product-detail-summary';
import ProductDetailTransport from './pages/product/product-detail/tabs/product-detail-transport';
import ProductsPage from './pages/product/product-list-page';
import ProductionReportPage from './pages/reports/production-report-page';
import PurchasingReportPage from './pages/reports/purchasing-report-page';
import ReportsPage from './pages/reports/reports-list-page';
import SalesReportPage from './pages/reports/sales-report-page';
import SettingsPage from './pages/settings/settings-page';
import CreateTransportPage from './pages/transport/create-transport-page';
import TransportPage from './pages/transport/transport-list-page';
import PcfAccessPage from './pcf/pcf-access-page';
import { pcfRoutes } from './pcf/pcf-routes';

function Routes() {
  const { user } = useAuth();

  const productOnlyRoute = (element: JSX.Element) =>
    user?.productCategory === 'product' ? element : <PageErrorForbidden />;

  return (
    <RoutesContainer>
      <Route element={<AuthenticatedRoutes />}>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />

          <Route path={pcfRoutes.PCF_ACCESS} element={<PcfAccessPage />} />
          <Route
            element={
              <FeatureRequired
                feature="pcf"
                redirectTo={pcfRoutes.PCF_ACCESS}
              />
            }
          >
            <Route path="/products/dashboard" element={<DashboardPage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/products/create" element={<CreateProductPage />} />
            <Route path="/products/:product_id" element={<ProductDetailPage />}>
              <Route path="" element={<ProductDetailSummary />} />
              <Route
                path="ingredients"
                element={<ProductDetailIngredients />}
              />
              <Route path="processing" element={<ProductDetailProcessing />} />
              <Route path="packaging" element={<ProductDetailPackaging />} />
              <Route path="transport" element={<ProductDetailTransport />} />
            </Route>
            <Route
              path="/products/:product_id/result"
              element={<ProductResult />}
            />
            <Route
              path="/products/:product_id/edit"
              element={<EditProductRoute />}
            />
            <Route path="/collections" element={<CollectionListPage />} />
            <Route
              path="/collections/:collection_id"
              element={<CollectionDetailPage />}
            >
              <Route path="" element={<CollectionDetailSummary />} />
              <Route path="products" element={<CollectionDetailProducts />} />
              <Route
                path="ingredients"
                element={<CollectionDetailIngredients />}
              />
            </Route>
            <Route
              path="/collections/:collection_id/edit"
              element={<EditCollectionPage />}
            />
            <Route
              path="/collections/create"
              element={<CreateCollectionPage />}
            />

            {/* <Route path="/ingredients" element={<IngredientsPage />} / */}
            <Route path="/ingredients" element={<IngredientsLayout />}>
              <Route path="" element={<IngredientsPage />} />
              <Route path="insights" element={<IngredientInsightsPage />} />
            </Route>
            <Route
              path="/ingredients/create"
              element={<CreateIngredientPage />}
            />
            <Route path="/packaging" element={<PackagingListPage />} />
            <Route
              path="/packaging/create"
              element={productOnlyRoute(<PackagingCreatePage />)}
            />
            <Route
              path="/packaging/:packaging_id"
              element={productOnlyRoute(<PackagingDetailPage />)}
            />
            <Route
              path="/processing"
              element={productOnlyRoute(<ProcessingPage />)}
            />
            <Route
              path="/processing/create"
              element={productOnlyRoute(<CreateProcessingPage />)}
            />
            <Route
              path="/transport"
              element={productOnlyRoute(<TransportPage />)}
            />
            <Route
              path="/transport/create"
              element={productOnlyRoute(<CreateTransportPage />)}
            />
            <Route path="/product-comparison" element={<ComparisonPage />} />
          </Route>

          <Route path="/reports" element={<ReportsPage />} />
          <Route
            path="/reports"
            element={
              <FeatureRequired feature="reporting" redirectTo="/reports" />
            }
          >
            <Route path="sales/:report_id" element={<SalesReportPage />} />
            <Route
              path="purchasing/:report_id"
              element={<PurchasingReportPage />}
            />
            <Route
              path="production/:report_id"
              element={<ProductionReportPage />}
            />
          </Route>

          <Route
            path={ccfRoutes.COMPANY_REPORT_ACCESS}
            element={<CcfAccessPage />}
          />
          <Route
            path="/company-reports"
            element={
              <FeatureRequired
                feature="ccf"
                redirectTo={ccfRoutes.COMPANY_REPORT_ACCESS}
              />
            }
          >
            <Route path="" element={<CCFListPage />} />
            <Route path=":report_uuid" element={<CCFLayout />}>
              <Route path="" element={<ReportHubPage />} />
              <Route
                path="stationary-combustion"
                element={<StationaryCombustionPage />}
              />
              <Route
                path="mobile-combustion"
                element={<MobileCombustionPage />}
              />
              <Route path="other-gas" element={<OtherGasEmissionsPage />} />

              <Route path="electricity" element={<ElectricityPage />} />
              <Route
                path="other-energy"
                element={<OtherEnergyEmissionsPage />}
              />

              <Route path="food" element={<CCFFoodAndDrinkPage />} />
            </Route>
          </Route>
          <Route path="/settings" element={<SettingsPage />} />
        </Route>
        <Route
          path="/company-reports/:report_uuid/food-and-drink/import"
          element={<FoodImportLayout />}
        >
          <Route path="" element={<FoodImportPage />} />
          <Route path="download" element={<FoodDownloadTemplatePage />} />
          <Route path="importing" element={<FoodImportLoadingPage />} />
        </Route>

        <Route element={<OnboardingGuideLayout />}>
          <Route path="/guide/welcome" element={<WelcomePage />} />
          <Route path="/guide/products" element={<ProductsGuide />} />
          <Route path="/guide/assessments" element={<AssessmentsGuide />} />
          <Route path="/guide/reports" element={<ReportsGuide />} />
          <Route path="/guide/get-in-touch" element={<GetInTouch />} />

          <Route
            path="/company-reports/welcome"
            element={<ReportSetupWelcome />}
          />
          <Route path="/company-reports/setup" element={<CCFSetup />} />
        </Route>
      </Route>

      <Route element={<SignupFlowLayout />}>
        <Route path="/sign-up/about-company" element={<AboutCompanyPage />} />
        <Route path="/sign-up/company-aims" element={<CompanyAimsPage />} />
        <Route path="/verify-email" element={<CheckEmailPage />} />
      </Route>

      {/* This are public routes */}
      <Route element={<AuthLayout />}>
        <Route path="/password/forgot" element={<ForgotPasswordPage />} />
        <Route
          path="/password/change/:uid/:token"
          element={<PasswordResetPage />}
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignupPage />} />
        <Route path="/verify-email/:key" element={<VerifyEmailPage />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </RoutesContainer>
  );
}

export default Routes;
