import Page from '@app/components/layout/page/page';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateFoodImport, useGetCompanyReportPeriods } from '@shared/api';
import { TimePeriodTypeEnum } from '@shared/api/types';
import CsvImporterFood from '@shared/components/csv-import/food/csv-importer-food';
import {
  FoodImportData,
  FoodImportRow,
} from '@shared/components/csv-import/food/types';
import SelectFormField, {
  SelectOption,
} from '@shared/components/form/select-form-field';
import GuideTemplate from '@shared/components/guide-template';
import Loader from '@shared/components/loader';
import { Form, FormLabel } from '@shared/components/ui/form';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@shared/components/ui/select';
import { capitalizeFirstLetter } from '@shared/lib/utils';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';
import { object, z } from 'zod';
import { ccfRoutes } from '../../../ccf-routes';
import { useFoodImport } from './food-import-layout';

const periodSchema = object({
  periodUuid: z.string().min(1),
});

export default function FoodImportPeriodPage() {
  const [timePeriod, setTimePeriod] = useState<string>(TimePeriodTypeEnum.year);
  const { reportUuid, setImportUuid, reportTitle, setNumberOfActivities } =
    useFoodImport();
  const { data: periods, isLoading: isPeriodsLoading } =
    useGetCompanyReportPeriods(reportUuid);
  const { mutateAsync: createFoodImport } = useCreateFoodImport();
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof periodSchema>>({
    resolver: zodResolver(periodSchema),
  });

  const { getValues, setValue, handleSubmit } = form;

  const [importerOpen, setImporterOpen] = useState(false);
  const openImporter = () => {
    setImporterOpen(true);
  };

  const closeImporter = () => setImporterOpen(false);

  const getPeriodOptions = useMemo(() => {
    return periods
      ?.filter((period) => period.type === timePeriod)
      .map(({ uuid, name }) => ({
        value: uuid,
        label: name,
      })) as SelectOption[] | [];
  }, [periods, timePeriod]);

  const startImport = async (rows: FoodImportData) => {
    const importData = {
      companyReportUuid: reportUuid,
      data: {
        timePeriod: getValues('periodUuid'),
        activities: rows as FoodImportRow[],
      },
    };

    const { uuid: importUuid } = await createFoodImport(importData);
    setNumberOfActivities(rows.length);
    setImportUuid(importUuid);
    navigate(
      generatePath(ccfRoutes.IMPORTING_FOOD, { report_uuid: reportUuid })
    );
  };

  useEffect(() => {
    if (timePeriod == 'year' && getPeriodOptions) {
      setValue('periodUuid', getPeriodOptions[0].value);
    }
  }, [timePeriod, getPeriodOptions, form, setValue]);

  return (
    <Page name={`Food and drink - Import | ${reportTitle}`}>
      <GuideTemplate
        preTitle="Import food and drink purchases"
        title="Upload your spreadsheet"
        description="You can upload multiple sheets and assign each one to a specific time period, doing so will allow you to compare your emissions from food across time periods. If your data isn’t organised by time period, submit it for the whole year."
        primaryActionProps={{
          disabled: isPeriodsLoading,
          onClick: handleSubmit(openImporter),
        }}
        primaryActionText="Begin import"
        secondaryActionText="Back"
        secondaryActionProps={{
          disabled: isPeriodsLoading,
          onClick: () => navigate(-1),
        }}
      >
        {periods && (
          <Form {...form}>
            <form onSubmit={handleSubmit(openImporter)} className="text-left">
              <FormLabel>Period</FormLabel>
              <div className="mt-1 flex items-end space-x-2">
                <Select
                  defaultValue="year"
                  onValueChange={(value) => {
                    setTimePeriod(value);
                    setValue('periodUuid', '');
                  }}
                  disabled={isPeriodsLoading}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Select period" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {Object.keys(TimePeriodTypeEnum).map((period) => {
                        return (
                          <SelectItem value={period} key={period}>
                            {capitalizeFirstLetter(period)}
                          </SelectItem>
                        );
                      })}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <SelectFormField
                  name="periodUuid"
                  className="w-[260px]"
                  options={getPeriodOptions}
                  disabled={isPeriodsLoading}
                />
              </div>
            </form>
          </Form>
        )}

        {isPeriodsLoading && <Loader />}

        <CsvImporterFood
          isOpen={importerOpen}
          onClose={closeImporter}
          handleSubmit={startImport}
        />
      </GuideTemplate>
    </Page>
  );
}
