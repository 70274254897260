import Page from '@app/components/layout/page/page';
import Text from '@shared/components/content/text';
import TextLink from '@shared/components/content/text-link';
import GuideTemplate from '@shared/components/guide-template';
import { generatePath, useNavigate } from 'react-router-dom';
import { ccfRoutes } from '../ccf-routes';

export default function CCFSetupWelcome() {
  const navigate = useNavigate();

  return (
    <Page name="Report welcome page">
      <GuideTemplate
        title="Company Carbon Footprint"
        description={
          <div className="space-y-4">
            <Text>
              Welcome to the first step in creating your Company Carbon
              Footprint report
            </Text>
            <Text>
              We understand that carbon reporting can seem complex, but we are
              here to simplify the process for you. Our platform offers clear,
              step-by-step instructions and practical tips to ensure you gain
              fast and accurate results. If you have any questions, our team is
              a click away.
            </Text>
            <Text>
              You can reach us via live chat in the bottom right corner or visit
              our{' '}
              <TextLink
                className="text-primary-600"
                internal={false}
                to="https://help.myemissions.co/en/"
              >
                Help Center
              </TextLink>{' '}
              for further support.
            </Text>
          </div>
        }
        primaryActionProps={{
          onClick: () => navigate(generatePath(ccfRoutes.REPORT_SETUP)),
        }}
        primaryActionText="Get started"
      >
        <div className="size-full bg-gray-100"></div>
      </GuideTemplate>
    </Page>
  );
}
