import ApiValidationError from '@app/api/errors';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAssessProduct, useUpdateProduct } from '@shared/api';
import {
  PatchedProductWithRelations,
  ProductIngredientEntityTypeEnum,
  ProductWithRelations,
} from '@shared/api/types';
import { useSetFormFields } from '@shared/components/product-form/hooks/use-set-form-fields';
import ProductForm from '@shared/components/product-form/product-form';
import { useToast } from '@shared/components/ui/use-toast';
import { cn } from '@shared/lib/utils';
import sentry from '@shared/services/sentry';
import { ClassValue } from 'clsx';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import {
  ingredientsSchema,
  netWeightSchema,
  packagingListSchema,
  recipeNameSchema,
  servingsSchema,
} from '../../../shared/components/product-form/schema';

export const editDishFormSchema = z.object({
  recipeName: recipeNameSchema,
  servings: servingsSchema,
  netWeight: netWeightSchema,
  ingredients: ingredientsSchema,
  packaging: packagingListSchema,
});

interface EditDishFormProps {
  className?: ClassValue;
  defaultValues?: z.infer<typeof editDishFormSchema>;
  productUuid: string;
}

export default function EditDishForm({
  className,
  defaultValues,
  productUuid,
}: EditDishFormProps) {
  const navigate = useNavigate();
  const { toast } = useToast();

  const { mutateAsync: updateProduct } = useUpdateProduct();
  const { mutateAsync: assessProduct } = useAssessProduct();

  const form = useForm<z.infer<typeof editDishFormSchema>>({
    resolver: zodResolver(editDishFormSchema),
    defaultValues: defaultValues || {
      ingredients: [
        {
          entityUuid: '',
          quantity: 1,
          unitUuid: '',
          entityType: ProductIngredientEntityTypeEnum.base_food,
        },
      ],
    },
  });

  const handleAssess = async (productUuid: string) => {
    try {
      await assessProduct({ productUuid });
      toast({
        title: 'Your product was successfully reassessed!',
        variant: 'success',
      });
      navigate(`/products/${productUuid}/`);
    } catch (error) {
      toast({
        title: 'An error occurred while reassessing your product',
        description: 'Please try again or contact us if the error persists',
        variant: 'destructive',
      });
      sentry.log(error);
    }
  };

  const handleEdit = async (data: z.infer<typeof editDishFormSchema>) => {
    try {
      const updatedProduct = await updateProduct({
        productUuid,
        data: {
          name: data.recipeName,
          servings: data.servings,
          netWeight: data.netWeight || null,
          ingredients:
            data.ingredients as PatchedProductWithRelations['ingredients'],
          productPackagingTypes: data.packaging?.map((packaging) => {
            return { packagingType: packaging.packagingType };
          }) as ProductWithRelations['productPackagingTypes'],
        },
      });
      await handleAssess(updatedProduct.uuid);
    } catch (error) {
      const description =
        error instanceof ApiValidationError
          ? error.message
          : 'Please try again or contact us if the error persists';
      toast({
        title: 'An error occurred while updating your product',
        description,
        variant: 'destructive',
      });
      sentry.log(error);
    }
  };

  return (
    <div className={cn('', className)}>
      <ProductForm
        form={form}
        fields={useSetFormFields(editDishFormSchema)}
        submitText="Reassess product"
        handleSubmit={handleEdit}
        secondaryButtonText="Discard changes"
        secondaryButtonAction={() => navigate(`/products/`)}
      />
    </div>
  );
}
