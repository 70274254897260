import {} from 'react-spreadsheet-import';
import { ErrorLevel, RowHook } from 'react-spreadsheet-import/types/types';
import { FoodImportRow } from './types';

const sanitizeNumberString = (str: string) => {
  return str.replace(/[^0-9.]/g, '');
};

const sanitizeFoodRow = (row: FoodImportRow) => {
  return {
    ...row,
    name: row.name.trim(),
    remoteId: row.remoteId?.trim(),
    supplier: row.supplier?.trim(),
    quantity: sanitizeNumberString(row.quantity),
  };
};

const validateQuantity = (quantity: string) => {
  if (isNaN(Number(quantity))) {
    return {
      level: 'error' as ErrorLevel,
      message: 'Quantity must be a number',
    };
  }

  if (Number(quantity) <= 0) {
    return {
      level: 'error' as ErrorLevel,
      message: 'Quantity must be greater than 0',
    };
  }
};

export const validateFoodRow: RowHook<keyof FoodImportRow> = (
  row,
  addError
) => {
  const sanitized = sanitizeFoodRow(row as FoodImportRow);

  const { quantity } = sanitized;

  const quantityError = validateQuantity(quantity);
  if (quantityError) addError('quantity', quantityError);

  return sanitizeFoodRow(row as FoodImportRow);
};
