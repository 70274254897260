import { FoodActivities } from '@shared/api/types';
import Text from '@shared/components/content/text';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { NumberCell } from '@shared/components/data-table/cells/number-cell';
import RatingCloud, {
  RatingCloudProps,
} from '@shared/components/ratings/rating-cloud';
import { ColumnDef } from '@tanstack/react-table';

export const foodTableColumns: ColumnDef<FoodActivities>[] = [
  {
    accessorKey: 'uuid',
    enableHiding: true,
  },
  {
    accessorKey: 'period',
    enableHiding: true,
  },
  {
    accessorKey: 'remoteId',
    header: ({ column }) => <HeaderCell column={column} name="Your ID" />,
    cell: ({ getValue }) => {
      return <Text>{getValue<string>()}</Text>;
    },
  },
  {
    accessorKey: 'supplier',
    header: ({ column }) => <HeaderCell column={column} name="Supplier" />,
    cell: ({ getValue }) => {
      const supplier = getValue<FoodActivities['supplier'] | null>();
      return <Text className="text-nowrap">{supplier && supplier.name}</Text>;
    },
  },
  {
    accessorKey: 'food',
    accessorFn: (row) => row.organizationFood,
    header: ({ column }) => <HeaderCell column={column} name="Name" />,
    cell: ({ getValue }) => {
      const food = getValue<FoodActivities['organizationFood']>();
      return <Text className="w-full min-w-40 max-w-28">{food.name}</Text>;
    },
  },
  {
    accessorKey: 'matchedBaseFood',
    accessorFn: (row) => row.matchedBaseFood.name,
    header: ({ column }) => <HeaderCell column={column} name="Matched food" />,
    cell: ({ getValue }) => {
      const foodName = getValue<FoodActivities['matchedBaseFood']['name']>();
      return <Text>{foodName}</Text>;
    },
  },
  {
    accessorKey: 'matchedBaseFoodRating',
    accessorFn: (row) => row.matchedBaseFood.rating,
    header: ({ column }) => <HeaderCell column={column} name="Rating" />,
    cell: ({ getValue }) => {
      const matchedBaseFoodRating =
        getValue<FoodActivities['matchedBaseFood']['rating']>();
      return (
        <RatingCloud
          rating={matchedBaseFoodRating as RatingCloudProps['rating']}
        />
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'emissionFactor',
    header: ({ column }) => (
      <HeaderCell
        column={column}
        name="Emissions Factor"
        numberColumn
        unit={
          <Text variant="subtle">
            (kgCO<sub>2</sub>e/kg)
          </Text>
        }
      />
    ),
    cell: ({ getValue }) => {
      const emissionFactor = getValue<number>();
      return <NumberCell number={Number(emissionFactor)} rounded />;
    },
  },
  {
    accessorKey: 'quantity',
    header: ({ column }) => (
      <HeaderCell
        column={column}
        name="Quantity"
        numberColumn
        unit={<Text variant="subtle">(kg)</Text>}
      />
    ),
    cell: ({ getValue }) => {
      const quantity = getValue<number>();
      return <NumberCell number={quantity} rounded />;
    },
  },
  {
    accessorKey: 'emissions',
    header: ({ column }) => (
      <HeaderCell
        column={column}
        name="Emissions"
        numberColumn
        unit={
          <Text variant="subtle">
            (tCO<sub>2</sub>e)
          </Text>
        }
      />
    ),
    cell: ({ getValue }) => {
      return <NumberCell number={getValue<number>()} rounded />;
    },
  },
];
