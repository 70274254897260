import useAuth from '@app/auth/use-auth';
import { AccountResponse } from '@shared/api/types';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

interface FeatureRequiredProps {
  feature: keyof AccountResponse['features'];
  redirectTo: string;
}

export default function FeatureRequired({
  feature,
  redirectTo,
}: FeatureRequiredProps) {
  const { user } = useAuth();
  const { features } = user!;
  const navigate = useNavigate();

  useEffect(() => {
    if (!features[feature]) {
      navigate(redirectTo);
    }
  }, [feature, features, navigate, redirectTo]);

  return <Outlet />;
}
