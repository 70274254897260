export function companyReportTabs(companyReportUuid: string) {
  return [
    {
      title: 'Summary',
      href: `/company-reports/${companyReportUuid}`,
    },
    {
      title: 'Scope 1',
      disabled: true,
      href: `/company-reports/${companyReportUuid}/stationary-combustion`,
      subTabs: [
        {
          title: 'Stationary combustion',
          href: `/company-reports/${companyReportUuid}/stationary-combustion`,
        },
        {
          title: 'Mobile combustion',
          href: `/company-reports/${companyReportUuid}/mobile-combustion`,
        },
        {
          title: 'Other gas emissions',
          href: `/company-reports/${companyReportUuid}/other-gas`,
        },
      ],
    },
    {
      title: 'Scope 2',
      disabled: true,
      href: `/company-reports/${companyReportUuid}/electricity`,
      subTabs: [
        {
          title: 'Electricity',
          href: `/company-reports/${companyReportUuid}/electricity`,
        },
        {
          title: 'Other purchased energy',
          href: `/company-reports/${companyReportUuid}/other-energy`,
        },
      ],
    },
    {
      title: 'Scope 3',
      href: `/company-reports/${companyReportUuid}/food`,
      subTabs: [
        {
          title: 'Food and Drink',
          href: `/company-reports/${companyReportUuid}/food`,
        },
      ],
    },
  ];
}
