import useTailwind from '@shared/styles/useTailwind';

export const useImporterTheme = () => {
  const { theme } = useTailwind();

  return {
    fonts: {
      heading: 'TWKBurns, sans-serif',
      body: 'Inter, sans-serif',
    },
    colors: {
      secondaryBackground: theme.colors.teal['50'],
      rsi: {
        ...theme.colors.primary,
        '500': theme.colors.primary['700'],
      },
      green: theme.colors.secondary,
      yellow: theme.colors.warning,
      red: {
        ...theme.colors.error,
        '500': theme.colors.error['400'],
      },
    },
    components: {
      Button: {
        baseStyle: {
          maxWidth: 'min-content',
        },
      },
    },
  };
};
