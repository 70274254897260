import Page from '@app/components/layout/page/page';
import { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ccfRoutes } from '../../ccf-routes';
import { useCCF } from '../ccf-layout';
import EmptyCategoryPage from '../components/ccf-category-intro-page';
import FoodTable from './ccf-food-table';

export default function CCFFoodAndDrinkPage() {
  const navigate = useNavigate();
  const { reportUuid, summary } = useCCF();

  const foodStatus = useMemo(
    () => summary?.filter((item) => item.slug === 'food')[0].status,
    [summary]
  );

  return (
    <Page title="Scope 3 - Food and drink" name="Scope 3 - Food and drink">
      {foodStatus != 'unstarted' ? (
        <FoodTable />
      ) : (
        <EmptyCategoryPage
          category="Food and drink"
          description="The food and drink that your company purchases. This falls under Scope 3, Category 1 of the GHG protocol, Purchased Goods & Services. We expect this to account for ~70% of the total emissions of a food business."
          primaryActionProps={{
            onClick: () =>
              navigate(
                generatePath(ccfRoutes.IMPORT_DOWNLOAD, {
                  report_uuid: reportUuid,
                })
              ),
          }}
          secondaryActionProps={{
            onClick: () =>
              window.open(
                'https://help.myemissions.co/en/articles/10116648-overview-scope-1-2-and-3',
                '_blank'
              ),
          }}
          image="https://via.placeholder.com/150"
        />
      )}
    </Page>
  );
}
