import ApiValidationError from '@app/api/errors';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAssessProduct, useCreateProduct } from '@shared/api';
import {
  ProductIngredientEntityTypeEnum,
  ProductWithRelations,
} from '@shared/api/types';
import { useSetFormFields } from '@shared/components/product-form/hooks/use-set-form-fields';
import ProductForm from '@shared/components/product-form/product-form';
import { useToast } from '@shared/components/ui/use-toast';
import { cn } from '@shared/lib/utils';
import sentry from '@shared/services/sentry';
import { ClassValue } from 'clsx';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import {
  collectionsSchema,
  ingredientsSchema,
  netWeightSchema,
  packagingListSchema,
  recipeNameSchema,
  reggieUuidSchema,
  servingsSchema,
} from '../../../shared/components/product-form/schema';

const createDishFormSchema = z.object({
  recipeName: recipeNameSchema,
  servings: servingsSchema,
  netWeight: netWeightSchema,
  ingredients: ingredientsSchema,
  packaging: packagingListSchema,
  collections: collectionsSchema,
  reggieUuid: reggieUuidSchema,
});

interface CreateDishFormProps {
  className?: ClassValue;
}

export default function CreateDishForm({ className }: CreateDishFormProps) {
  const navigate = useNavigate();
  const { toast } = useToast();

  const { mutateAsync: createProduct } = useCreateProduct();
  const { mutateAsync: assessProduct } = useAssessProduct();

  const form = useForm<z.infer<typeof createDishFormSchema>>({
    resolver: zodResolver(createDishFormSchema),
    defaultValues: {
      servings: 1,
      ingredients: [
        {
          entityUuid: '',
          quantity: 1,
          unitUuid: '',
          entityType: ProductIngredientEntityTypeEnum['base_food'],
        },
      ],
    },
  });

  const handleAssess = async (productUuid: string) => {
    try {
      await assessProduct({ productUuid });
      navigate(`/products/${productUuid}/result`);
    } catch (error) {
      toast({
        title: 'Your product is being assessed',
        variant: 'default',
      });
      sentry.log(error);
      navigate(`/products`);
    }
  };

  const handleCreate = async (data: z.infer<typeof createDishFormSchema>) => {
    try {
      const newProduct = await createProduct({
        data: {
          name: data.recipeName,
          servings: data.servings,
          netWeight: data.netWeight || null,
          collections: data.collections,
          ingredients: data.ingredients as ProductWithRelations['ingredients'],
          productPackagingTypes: data.packaging?.map((packaging) => {
            return { packagingType: packaging.packagingType };
          }) as ProductWithRelations['productPackagingTypes'],
          reggieUuid: data.reggieUuid,
        },
      });

      await handleAssess(newProduct.uuid);
    } catch (error) {
      const description =
        error instanceof ApiValidationError
          ? error.message
          : 'Please try again or contact us if the error persists';
      toast({
        title: 'An error occurred while creating your product',
        description,
        variant: 'destructive',
      });
      sentry.log(error);
    }
  };

  return (
    <div className={cn('', className)}>
      <ProductForm
        form={form}
        fields={useSetFormFields(createDishFormSchema)}
        submitText="Assess product"
        handleSubmit={handleCreate}
        includeReggie
      />
    </div>
  );
}
