import { Icons } from '@shared/components/content/icons';
import { generatePath } from 'react-router-dom';
import { ccfRoutes } from '../ccf-routes';
import { useCCF } from './ccf-layout';

export type ScopeCategory = {
  scope: number;
  name: string;
  slug: string;
  description: string;
  href: string;
  icon: React.ReactNode;
  cardStatus: 'disabled' | 'default' | 'excluded';
  totalEmissions?: number;
  statusDescription?: string;
  index?: number;
};

export function useScopeCategories(): ScopeCategory[] {
  const { reportUuid: report_uuid } = useCCF();
  return [
    {
      scope: 1,
      name: 'Stationary combustion',
      slug: 'stationary-combustion',
      description: 'Fuels combusted directly on your sites',
      href: generatePath(ccfRoutes.STATIONARY_COMBUSTION, {
        report_uuid,
      }),
      icon: <Icons.flame className=" text-orange-400" size={20} />,
      cardStatus: 'disabled',
    },
    {
      scope: 1,
      name: 'Mobile combustion',
      slug: 'mobile-combustion',
      description: 'Fuels combusted in your vehicles',
      href: generatePath(ccfRoutes.MOBILE_COMBUSTION, {
        report_uuid,
      }),
      icon: <Icons.truck className=" text-orange-400" size={20} />,
      cardStatus: 'disabled',
    },
    {
      scope: 1,
      name: 'Other gas emissions',
      slug: 'other-gas',
      description: 'From fridges, extinguishers and fermentation',
      href: generatePath(ccfRoutes.OTHER_GAS, {
        report_uuid,
      }),
      icon: <Icons.refrigerator className=" text-orange-400" size={20} />,
      cardStatus: 'disabled',
    },

    {
      scope: 2,
      name: 'Electricity',
      slug: 'electricity',
      description: 'Electricity consumed across your sites',
      href: generatePath(ccfRoutes.ELECTRICITY, {
        report_uuid,
      }),
      icon: <Icons.zap className=" text-orange-400" size={20} />,
      cardStatus: 'disabled',
    },
    {
      scope: 2,
      name: 'Other purchased energy',
      slug: 'other-energy',
      description: 'Heating, cooling and steam purchased',
      href: generatePath(ccfRoutes.OTHER_ENERGY, {
        report_uuid,
      }),
      icon: <Icons.snowflake className=" text-orange-400" size={20} />,
      cardStatus: 'disabled',
    },

    {
      scope: 3,
      name: 'Food and Drink',
      slug: 'food',
      description: 'Food and drink you have purchased',
      href: generatePath(ccfRoutes.FOOD, {
        report_uuid,
      }),
      icon: <Icons.carrot className=" text-orange-400" size={20} />,
      cardStatus: 'default',
    },
  ];
}
