import Navbar from '@app/components/layout/nav/navbar';
import Page from '@app/components/layout/page/page';
import { useGetCompanyReport } from '@shared/api';
import { useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';

export type FoodImportContext = {
  reportTitle?: string;
  reportUuid: string;
  importUuid?: string;
  setImportUuid: (uuid: string) => void;
  numberOfActivities?: number;
  setNumberOfActivities: (num: number) => void;
};

export function useFoodImport() {
  return useOutletContext<FoodImportContext>();
}

export default function FoodImportLayout() {
  const params = useParams();
  const companyReportUuid = params.report_uuid;
  const [importUuid, setImportUuid] = useState<string | undefined>();
  const [numberOfActivities, setNumberOfActivities] = useState<
    number | undefined
  >();

  const { data, status: companyReportStatus } = useGetCompanyReport(
    companyReportUuid!
  );

  const context: FoodImportContext = {
    reportTitle: data?.title,
    reportUuid: companyReportUuid!,
    importUuid,
    setImportUuid,
    numberOfActivities,
    setNumberOfActivities,
  };

  return (
    <Page
      name="Food and drink import layout"
      page="layout"
      status={companyReportStatus}
    >
      <div className="flex h-full min-h-screen flex-col bg-gradient-to-r from-teal-300  to-teal-100">
        <Navbar
          logo={{
            variant: 'name',
            color: 'dark',
          }}
          includeCloseButton
        />
        <div className="grid flex-1 grid-cols-1 overflow-y-auto p-2">
          <Outlet context={context} />
        </div>
      </div>
    </Page>
  );
}
